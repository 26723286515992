import React from 'react';

import Popup from 'components/popup/Popup';
import Form from 'parts/form/Form';
import NaeringsserviceLead from 'components/forms/custom-forms/NaeringsserviceLead';

export default function Popups({ showContact = true, showTest = true }) {
	return (
		<>
			{showContact && (
				<Popup id="modal-form-contact" title="Ta kontakt" size="medium">
					<NaeringsserviceLead />
				</Popup>
			)}

			{showTest && (
				<Popup
					id="modal-form-test"
					title="Ta vår kontrolltest"
					size="large"
					padding="false">
					{/*Typeform "Kontroll-test"*/}
					<Form
						typeformUrl={`https://sgdor11z5w7.typeform.com/to/kEBVfrQZ`}
						title="Ta vår kontrolltest"
						intro="Legg igjen din kontaktinformasjon for å bli kontaktet av oss."
						blockId="kontrolltest-skjema"
						spacing={{ top: 'none', bottom: 'none' }}
					/>
				</Popup>
			)}
		</>
	);
}
